import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../_common/snackBar.service';
import { Utilities } from '../_common/utils';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { CustomerAddDocPopupComponent } from './customerAddDocPopup.component';
import { CustomerDocEditPopupComponent } from './customerDocEditPopup.component';
import { CustomerService } from './data/customer.service';
import { CustomerDocTypesResponseDto } from './data/dto/customer.dto';
import { CustomerDocumentDto } from './data/dto/customerDocument.dto';
import {BranchesService} from "../branch/data/branches.service";
import { CommentPopupComponent } from './commentPopup.compoent';
import { DocumentImagesComponent } from './documentImages.component';

@Component({
  selector: 'app-customerdocs',
  templateUrl: './customerDocumentPopup.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class CustomerDocPopupComponent {
  customerId!: number;
  docTypes: CustomerDocTypesResponseDto[] = [];
document: any;
customerDocuments : any;
customerStatus: any;
  constructor(
    public dialogRef: MatDialogRef<CustomerDocPopupComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { docs: CustomerDocumentDto[]; customerId: number },
    private customerService: CustomerService,
    private snackBar: SnackBarService,
    private branchesService: BranchesService
  ) {
    this.customerId = this.data.customerId;
    this.customerDocuments = this.data.docs[0];
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  openAddDocDialog(customerId: number) {
    this.customerService.getCustomerDocTypes().subscribe((types) => {
      this.branchesService.countries().subscribe(countries => {
      const dialogRef = this.dialog.open(CustomerAddDocPopupComponent, {
        width: '850px',
        disableClose: true,
        data: { types, customerId, countries : countries },
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;

        this.customerService
          .upsertCustomerDocument({
            documentDetails: resp.docData.documentDetails,
            id: 0,
            customerId: customerId,
            documentTypeId: resp.docData.documentTypeId,
          })
          .subscribe(
            (response) => {
              this.customerService
                .uploadDocumentAttachment(response.id, resp.docAttachments)
                .subscribe((resp) => {
                  this.snackBar.open(resp?.message);
                });
              this.dialogRef.close();
            },
            (err) => {
              this.snackBar.open(err.message);
            },
          );
      });
    }, ({ message }) => {
      this.snackBar.open(message);
    });
    });
  }

  onViewAttachment(attachments: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '1000px',
      data: attachments,
    });
  }

  onDocumentViewAttachment(selfieImage: string, documentImage: string, ormIdVerificationResultImage: string, documentTypeId: number, isFaceIdentical: boolean, faceConfidence: string): void {
    this.dialog.open(DocumentImagesComponent, {
      width: '1400px',
      maxHeight: '90vh',
      panelClass: 'scrollable-dialog',
      data: {
        imageUrl: selfieImage,
        documentImages: documentImage,
        ormIdVerificationResultImage: ormIdVerificationResultImage,
        documentTypeId: documentTypeId,
        isFaceIdentical: isFaceIdentical,
        faceConfidence: faceConfidence
      }
    });
  }
  editDocumentInfo(docId: number, customerId: number) {
    this.customerService.getCustomerDocTypes().subscribe((types) => {
      this.branchesService.countries().subscribe(countries => {
        const dialogRef = this.dialog.open(CustomerDocEditPopupComponent, {
          width: '850px',
          disableClose: true,
          data: {
            docId: docId,
            customerId: customerId,
            docInfo: this.data.docs,
            docTypes: types,
            countries : countries
          },
        });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;
        this.customerService
          .upsertCustomerDocument({
            documentDetails: resp.docData.documentDetails,
            id: docId,
            customerId: customerId,
            documentTypeId: resp.docData.documentTypeId,
            isActive: resp.docData.isActive,
          })
          .subscribe(
            (response) => {
              this.customerService
                .uploadDocumentAttachment(response.id, resp.docAttachments)
                .subscribe((resp) => {
                  this.snackBar.open(
                    resp?.message,
                  );
                });
              this.dialogRef.close();
            },
            (err) => {
              this.snackBar.open(err);
            },
          );
      });
    }, ({ message }) => {
      this.snackBar.open(message);
      });
    });
  }

  openPopupCommentDialog(customerId: number, statusId: number){
    const dialogRef = this.dialog.open(CommentPopupComponent, {
      width: '600px',
      disableClose: false,
      data: { customerId: customerId, statusId: statusId},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerStatus = result;
        this.dialogRef.close();
      }
    });
  }
}