<div class="row">
    <div class="col-md-6">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="bg-light" colspan="2" scope="col">
                <h6 class="mb-0" >Docuement Details</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="bg-light" scope="col">Document Number</th>
              <td scope="col">{{ customerDocuments.partnerNumber }}</td>
            </tr>
            <tr>
              <th class="bg-light" scope="col">documentType</th>
              <td scope="col">{{ customerDocuments.documentType }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">issue By</th>
                <td scope="col">{{ customerDocuments.issueBy }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">issue Date</th>
                <td scope="col">{{ customerDocuments.issueDate }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Expiry Date</th>
                <td scope="col">{{ customerDocuments.expiryDate }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Date Created</th>
                <td scope="col">{{ customerDocuments.createdDate }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Customer status</th>
                <td scope="col">{{ customerDocuments.customerStatus }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Action</th>
                <td scope="col">
                    <div class="action-menu">
                        <button class="btn btn-outline-info btn-sm" [matMenuTriggerFor]="menu">
                            {{ 'Action' | translate }}
                        </button>
                        <mat-menu #menu="matMenu" class="action-menu-item">
                            <a  
                                (click)="customerDocuments.customerStatus !== 'verified' && openPopupCommentDialog(customerDocuments.customerId, 2)"
                                [disabled]="customerDocuments.customerStatus === 'verified'" 
                                mat-menu-item class="primary">
                                <mat-icon>verified</mat-icon>
                                {{ 'verify' | translate }}
                            </a>
                            <a  
                                (click)="customerDocuments.customerStatus !== 'rejected' && openPopupCommentDialog(customerDocuments.customerId, 3)"
                                [disabled]="customerDocuments.customerStatus === 'rejected'" 
                                mat-menu-item class="primary">
                                <mat-icon>cancel</mat-icon>
                                {{ 'reject' | translate }}
                            </a>
                            <a mat-menu-item class="primary" (click)="onDocumentViewAttachment(customerDocuments.customerSelfieImage, customerDocuments.documentBase64, customerDocuments.ormIdVerificationResultImage, customerDocuments.documentTypeId, customerDocuments.isFaceIdentical, customerDocuments.faceConfidence)">
                                <mat-icon>attach_file</mat-icon>
                                {{ 'view attachment' | translate }}
                            </a>
                        </mat-menu>
                    </div>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="bg-light" colspan="2" scope="col">
                <h6 class="mb-0">Partner Response</h6>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="bg-light" scope="col">Home Affairs Id</th>
              <td scope="col">{{ customerDocuments.partnerNumber }}</td>
            </tr>
            <tr>
              <th class="bg-light" scope="col">Home Affairs Name</th>
              <td scope="col">{{ customerDocuments.name }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Partner verification State</th>
                <td scope="col">{{ customerDocuments.ormIdVerificationPartnerState }}</td>
            </tr>
            <tr>
                <th class="bg-light" scope="col">Id Verification State</th>
                <td scope="col">{{ customerDocuments.ormIdVerificationState }}</td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
  
  