import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackBarService } from '../_common/snackBar.service';
import { MatTableDataSource } from '@angular/material/table';
import { RemittancesDto } from './data/dto/remittances.dto';
import { FinsurvReportFilter, RemittanceService } from './data/remittance.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { RemittanceHistoryChangeLogPopupComponent } from './remittanceHistoryChangeLogPopup.component';
import { FinsurvTransactionsPopupComponent } from './finsurvTransactionsPopup.component';
import { Utilities } from '../_common/utils';
import { RemittanceHistoryPopupComponent } from './remittanceHistoryPopup.component';
import { OrmRemittancesComponent } from './ormremittance.component';
import { FinsurvModificationsPopup } from './finsurvModificationsPopup.component';
import { AddFinsurvModificationPopup } from './addFinsurvModificationPopup.component';
import * as XLSX from 'xlsx';
import { forEach } from 'lodash';

@Component({
  selector: 'app-finsurv-transactions',
  templateUrl: './finsurv-transactions.component.html',
  styleUrls: ['./finsurv-transactions.component.css']
})
export class FinsurvTransactionsComponent implements OnInit {

   remittances = new MatTableDataSource<RemittancesDto>();
   form!: UntypedFormGroup;
   resultsLength = 0;
   isReportingManual: any =false;
   displayedColumns: string[] = [
    '#',
    'remittanceCode', 'senderName', 'senderMobile',
     'receiverName', 'receiverMobile', 'flow', 'bopCategory', 'branchCode', 'Date', 'remittanceStatus',
     'randAmount', 'foreignAmount', 'foreignCurrencyCode','trnReference', 'transactionReference','finsurvTransactionType', 'transactionstatus', 'actions'
  ];
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);
  constructor(
      private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.remittances.data = [];
    this.form = this.fb.group({
      remittanceCode: [null],
      ReportReference: [null],
      flow: [''],
      bopCategory: [''],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() : Promise<any> {
    return new Promise((resolve) => {
    const { remittanceCode, ReportReference, flow, bopCategory,startDate, endDate } = this.form.value;
    const filter: FinsurvReportFilter = {};
    if(remittanceCode) filter.remittanceCode = remittanceCode;
    if(ReportReference) filter.ReportReference = ReportReference;
    if(flow) filter.flow = flow;
    if(bopCategory) filter.bopCategory = bopCategory;
    if(startDate) filter.startDate = startDate;
    if(endDate) filter.endDate = endDate;

      this.remittanceService
        .getFinsurvTransactions(
          filter,
        )
        .subscribe((data) => {
          this.remittances.data = data;
        this.remittances.paginator = this.paginator;
        resolve(data); 
        }, ({ message }) => {
          this.snackBar.open(message);
          resolve(null);
        });
        
  })
}

  viewChangeLog(snapshot: any) {
    this.dialog.open(RemittanceHistoryChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: snapshot,
    });
  }

   viewFinsurvTransactionsDetails( data: any ) {
     const { startDate, endDate } = this.form.value;
      const dialogRef = this.dialog.open(FinsurvTransactionsPopupComponent, {
      data: { data: data, startDate: new Date(startDate), endDate: endDate },
      maxWidth: 'fit-content',
      maxHeight: 'fit-content',
    });


      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') {
          return;
        }
      });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }



  onViewHistoryClick(remittanceId: number) {
    this.remittanceService
      .getRemittanceHistory(remittanceId)
      .subscribe((remittanceHistory) => {
        this.dialog.open(RemittanceHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: remittanceHistory,
        });
      });
  }

  cancelTransaction(
   finsurvTransaction: any
  ){

    let finsurvData = {
      remittanceId: finsurvTransaction.remittanceId,
      remittanceCode: finsurvTransaction.remittanceCode,
      reference: finsurvTransaction.trnReference,
      remittanceStateId: finsurvTransaction.tranStateId,
      payDate: finsurvTransaction.pDate,
      transactionDetailId: finsurvTransaction.transactionDetailId,
      finsurvStateId: finsurvTransaction.finsurvStateId,
      transactionTypeId: finsurvTransaction.transactionTypeId,
      finSurvTransactionId: finsurvTransaction.finSurvTransactionId,
      operation: 'cancel',
      ...finsurvTransaction
    }

      this.openAddFinsurvModificationDialog(
        false, finsurvData
      ).then((formData) => {
        if (formData) {
          finsurvData.comment = formData.comment;
          this.remittanceService
            .cancelFinsurvTransactions(finsurvData)
            .subscribe(
              () => {
                this.snackBar.open('Transaction is Cancelled Successfully');
                this.onSubmit();
              },
              ({ message }) => {
                this.snackBar.open(message);
              }
            );
        }
      });
  }

  reverseTransaction(
   finsurvTransaction: any
  ){

    let finsurvData = {
      remittanceId: finsurvTransaction.remittanceId,
      remittanceCode: finsurvTransaction.remittanceCode,
      reference: finsurvTransaction.trnReference,
      remittanceStateId: finsurvTransaction.tranStateId,
      payDate: finsurvTransaction.pDate,
      transactionDetailId: finsurvTransaction.transactionDetailId,
      finsurvStateId: finsurvTransaction.finsurvStateId,
      transactionTypeId: finsurvTransaction.transactionTypeId,
      finSurvTransactionId: finsurvTransaction.finSurvTransactionId,
      operation: 'reverse',
      ...finsurvTransaction
    }


    this.openAddFinsurvModificationDialog(
      false,
      finsurvData
    ).then((formData) => {

        if (formData) {
          finsurvData.comment = formData.comment;

            this.remittanceService
              .reverseFinsurvTransactions(
                finsurvData,
              )
              .subscribe((data) => {
              this.snackBar.open('Transaction is reversed Successfully');
              this.onSubmit()
              }, ({ message }) => {
                this.snackBar.open(message);
            });
        }
      });

  }

  replaceTransaction(
   finsurvTransaction: any
  ){

    let finsurvData = {
      remittanceId: finsurvTransaction.remittanceId,
      remittanceCode: finsurvTransaction.remittanceCode,
      reference: finsurvTransaction.trnReference,
      remittanceStateId: finsurvTransaction.tranStateId,
      payDate: finsurvTransaction.pDate,
      transactionDetailId: finsurvTransaction.transactionDetailId,
      finsurvStateId: finsurvTransaction.finsurvStateId,
      transactionTypeId: finsurvTransaction.transactionTypeId,
      finSurvTransactionId: finsurvTransaction.finSurvTransactionId,
      operation: 'replace',
      ...finsurvTransaction
    }

    this.openAddFinsurvModificationDialog(
      true, 
      finsurvData
    ).then((formData) => {
      
        if (formData) {
          finsurvData.comment = formData.comment;
          finsurvData.receiverMobile = formData.receiverMobile;
           
          this.remittanceService
            .replaceFinsurvTransactions(
              finsurvData,
            )
            .subscribe((data) => {
            this.snackBar.open('Transaction is replaced Successfully');
            this.onSubmit()
            }, ({ message }) => {
              this.snackBar.open(message);
            });
        }
      });
  }


  reSubmitTransaction(
   finsurvTransaction: any
  ){

    let finsurvData = {
      remittanceId: finsurvTransaction.remittanceId,
      remittanceCode: finsurvTransaction.remittanceCode,
      reference: finsurvTransaction.trnReference,
      remittanceStateId: finsurvTransaction.tranStateId,
      payDate: finsurvTransaction.pDate,
      transactionDetailId: finsurvTransaction.transactionDetailId,
      finsurvStateId: finsurvTransaction.finsurvStateId,
      transactionTypeId: finsurvTransaction.transactionTypeId,
      finSurvTransactionId: finsurvTransaction.finSurvTransactionId,
      operation: 'resubmit',
      ...finsurvTransaction
    }


    this.openAddFinsurvModificationDialog(
      false,
      finsurvData
    ).then((formData) => {
      
        if (formData) {
            finsurvData.comment = formData.comment;
              
            this.remittanceService
            .resubmitFinsurvTransactions(
              finsurvData,
            )
            .subscribe((data) => {
            this.snackBar.open('Transaction is resubmitted Successfully');
            this.onSubmit()
            }, ({ message }) => {
              this.snackBar.open(message);
            });
        }
      });
  }

  onRemittanceClick(remittanceCode: string): void {
    if (remittanceCode !== null) {
      this.dialog.open(OrmRemittancesComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { statusId: null, remittanceCode: remittanceCode, limit: 1},
        panelClass: 'scrollable-dialog'
      });
    }
  }

  openFinsurvModificationDialog(remittanceCode: string) {
    this.remittanceService.getFinsurvModifications(remittanceCode).subscribe((finsurvModification) => {
      this.dialog.open(FinsurvModificationsPopup, {
        width: '150vw',
        maxHeight: '90vh',
        data: { finsurvModification, remittanceCode },
      });
    }, ({ message }) => {
      this.snackBar.open(message);
    });
  }

  openAddFinsurvModificationDialog(
    isReplacementReport: boolean = false,
    reportData: any
  ): Promise<any> {
      return new Promise((resolve) => {
        const dialogRef = this.dialog.open(AddFinsurvModificationPopup, {
          width: '600px',
          disableClose: false,
          data: {
            isReplacementReport, 
            receiverMobile: reportData.receiverMobile
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            resolve(result); 
          } else {
            resolve(null);
          }
        });
      });
  }

  exportToExcel() {
    this.onSubmit().then(() => {

      if(this.remittances.data.length < 1){
          this.snackBar.open('No data to export.', 'Ok');
          return;
      }
      
      const headers =  [
        'Remittance Code', 'Sender Name', 'Sender Mobile', 'Receiver Name', 'Receiver Mobile', 'Flow', 
        'BoP Category', 'Branch Code', 'Remittance Status', 'Date', 'Rand Amount', 'Foreign Amount', 
        'Foreign Currency Code', 'Batch No', 'Transaction Ref No', 'Transaction Type', 'Transaction Status'
      ];
      const allDate: any = this.remittances.data;

      const data: any = allDate.map((row: any) => [
        row.remittanceCode,           
        row.senderFullName,           
        row.senderMobile,             
        row.receiverFullName,        
        row.receiverMobile,           
        row.flow,                    
        row.bopCategory,              
        row.senderBranch,            
        row.sDate,                    
        row.remittanceStatus,         
        row.sendingLocalAmount,       
        row.receivingLocalAmount,     
        row.receivingCurrencyCode,    
        row.trnReference,
        row.transactionReference,             
        row.finsurvTransactionType,   
        row.tranState,     
      ]);

  
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
  
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
  
      XLSX.writeFile(wb, 'Finsurv_Transactions.xlsx');

  }).catch(() => {})
  }

}
